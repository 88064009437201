<template>
    <div id="dashboard">
        <DefaultPageComponent
        :loading="loading"
        :title="'Importação de tabelas para estratificação referente a: <b style=\'color:#eb694a;\'> '+ getDateTitle() +' </b>'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        not_scroll_top
        >

        <template #header>
            <b-col class="px-0">
                <GlassButton
                > Importações
                </GlassButton>
            </b-col>
        </template>

        <!-- CONTENT -->
        <div class="py-3">



            <b-row class="m-0">
                <span style="color: #eb694a;font-size: 22px;">
                    Guias
                </span>
            </b-row>
            <ImportationVariantComponent class="m-0"
            title="Guia de Fundos"
            :inputRef="'refGuiaFundos'"
            :status="data.find(e=>e.table_name.trim()=='Guia de Fundos')?.status"
            :file_name="data.find(e=>e.table_name.trim()=='Guia de Fundos')?.file_name"
            :importedDate="data.find(e=>e.table_name.trim()=='Guia de Fundos')?.created_at_date"
            :loading="data.find(e=>e.table_name.trim()=='Guia de Fundos')?.loading"
            @change="getFile"
            @import="postImport"
            ></ImportationVariantComponent>
            
            <div class="py-2"></div>

            <ImportationVariantComponent class="m-0"
            title="Guia de fundos previdência"
            :inputRef="'refGuiaFundosPrev'"
            :status="data.find(e=>e.table_name.trim()=='Guia de fundos previdência')?.status"
            :file_name="data.find(e=>e.table_name.trim()=='Guia de fundos previdência')?.file_name"
            :importedDate="data.find(e=>e.table_name.trim()=='Guia de fundos previdência')?.created_at_date"
            :loading="data.find(e=>e.table_name.trim()=='Guia de fundos previdência')?.loading"
            @change="getFile"
            @import="postImport"
            ></ImportationVariantComponent>




            <b-row class="m-0 pt-3 mt-4" style="border-top:1px solid #535252;">
                <span style="color: #eb694a;font-size: 22px;">
                    Custódias
                </span>
            </b-row>
            <ImportationVariantComponent class="m-0"
            title="Diversificação"
            :inputRef="'refDiversificacao'"
            :status="data.find(e=>e.table_name.trim()=='Diversificação')?.status"
            :file_name="data.find(e=>e.table_name.trim()=='Diversificação')?.file_name"
            :importedDate="data.find(e=>e.table_name.trim()=='Diversificação')?.created_at_date"
            :loading="data.find(e=>e.table_name.trim()=='Diversificação')?.loading"
            @change="getFile"
            @import="postImport"
            ></ImportationVariantComponent>
            
            <div class="py-2"></div>

            <ImportationVariantComponent class="m-0"
            title="XPUS"
            :quotation="String(quotation) || 'Sem informação'"
            :inputRef="'refXPUS'"
            :status="data.find(e=>e.table_name.trim()=='XPUS')?.status"
            :file_name="data.find(e=>e.table_name.trim()=='XPUS')?.file_name"
            :importedDate="data.find(e=>e.table_name.trim()=='XPUS')?.created_at_date"
            :loading="data.find(e=>e.table_name.trim()=='XPUS')?.loading"
            @change="getFile"
            @import="postImport"
            ></ImportationVariantComponent>

            


            <b-row class="m-0 pt-3 mt-4" style="border-top:1px solid #535252;">
                <span style="color: #eb694a;font-size: 22px;">
                    Contratação Fee Fixo
                </span>
            </b-row>
            <ImportationVariantComponent class="m-0"
            title="Contratação Fee Fixo"
            :inputRef="'refContratacaoFeeFixo'"
            :status="data.find(e=>e.table_name.trim()=='Contratação Fee Fixo')?.status"
            :file_name="data.find(e=>e.table_name.trim()=='Contratação Fee Fixo')?.file_name"
            :importedDate="data.find(e=>e.table_name.trim()=='Contratação Fee Fixo')?.created_at_date"
            :loading="data.find(e=>e.table_name.trim()=='Contratação Fee Fixo')?.loading"
            @change="getFile"
            @import="postImport"
            ></ImportationVariantComponent>




            <b-row class="m-0 pt-3 mt-4" style="border-top:1px solid #535252;">
                <span style="color: #eb694a;font-size: 22px;">
                    Comissões
                </span>
            </b-row>
            
            <div v-for="item,idx in listCommission" :key="idx">
                <ImportationVariantComponent class="m-0"
                :title="item.table_name"
                :inputRef="'ref'+item.table_name"
                :status="item.status"
                :file_name="item.file_name"
                :importedDate="item.created_at_date"
                :loading="item.loading"
                @change="getFile"
                @import="postImport"
                ></ImportationVariantComponent>
                
                <div v-if="idx!=listCommission.length-1" class="py-2"></div>
            </div>



            <b-row class="m-0 pt-3 mt-4" style="border-top:1px solid #535252;">
                <span style="color: #eb694a;font-size: 22px;">
                    Nova tabela de comissão
                </span>
            </b-row>

            <ImportationVariantComponent v-if="renderNew" class="m-0"
            inputLabel="Nome da tabela"
            :inputRef="'refNewTable'"
            @input="getNewTable"
            @change="getFile"
            @import="postImport"
            ></ImportationVariantComponent>

            <div class="py-2 mt-1"></div>

            <CustomButton class="px-4"
            :width="'100%'"
            >
                Processar Comissões PJ1
            </CustomButton>




            <b-row class="m-0 pt-3 mt-4" style="border-top:1px solid #535252;">
                <span style="color: #eb694a;font-size: 22px;">
                    Comissões PJ2
                </span>
            </b-row>
            <ImportationVariantComponent class="m-0 mb-5"
            title="Tabela de comissão PJ2"
            :inputRef="'refTabelaComissaoPJ2'"
            :status="data.find(e=>e.table_name.trim()=='Tabela de comissão PJ2')?.status"
            :file_name="data.find(e=>e.table_name.trim()=='Tabela de comissão PJ2')?.file_name"
            :importedDate="data.find(e=>e.table_name.trim()=='Tabela de comissão PJ2')?.created_at_date"
            :loading="data.find(e=>e.table_name.trim()=='Tabela de comissão PJ2')?.loading"
            @change="getFile"
            @import="postImport"
            ></ImportationVariantComponent>
            
        </div>

        </DefaultPageComponent>

    </div>  
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import GlassButton from '@/components/GlassButton.vue';
import CustomButton from '@/components/CustomButton.vue';
import ImportationVariantComponent from '@/components/ImportationVariantComponent.vue';
export default {
    name: 'ImportView',
    components:{
        CustomButton,
        GlassButton,
        DefaultPageComponent,
        ImportationVariantComponent,
    },
    data () {
        return {
            renderNew: true,
            loading: false,
            loadingSubmit: false,
            quotation: null,
            year: null,
            month: null,
            reference_date: null,
            new_table_name: null,
            data: [],
            listCommission: [],

        }
    },
    async mounted() {
        await this.getReferenceDate();
        this.getDateTitle();
        this.getQuotation();
        this.getStratification();
    },
    methods: {
        async getFile(file, table_name){
            console.log(table_name)
            console.log(this.data)
            var idx = this.data.findIndex(e=>e.table_name.trim()==table_name);
            if(idx>=0) this.data[idx].file=file;
            console.log(this.data[idx])
        },
        async getQuotation(){
            var day = this.reference_date.substring(8,10);
            var month = this.reference_date.substring(5,7);
            var year = this.reference_date.substring(0,4);
            const date = month+'-'+day+'-'+year;
            const url = "https://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoDolarDia(dataCotacao=@dataCotacao)?@dataCotacao='"+ date +"'&$top=1&$format=json&$select=cotacaoCompra"
            const response = await this.$axios.get(url);
            this.quotation = response.data.value[0]?.cotacaoCompra || null;
        },
        async getReferenceDate(){
            const response = await this.$axios.get('calculate-reference-date');
            this.reference_date = response.data.reference_date;
        },
        getListCommission(){
            const idxStart = this.data.findIndex(e=>e.table_name.trim()=='Contratação Fee Fixo');
            const idxEnd = this.data.findIndex(e=>e.table_name.trim()=='Tabela de comissão PJ2');
            this.listCommission = [...this.data].splice(idxStart+1, idxEnd-idxStart-1); 
            console.log(this.listCommission)
            
        },
        async getStratification(){
            try{
                this.loading=true;
                const response = await this.$axios.get('import-status-details?type=Estratificação&year='+this.year+'&month='+this.month);
                this.data = response.data;
                this.getListCommission()
            }
            catch(error){
                this.$swal({
                    title: 'Erro',
                    text: `Não foi possível carregar as importações.`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C7300C',
                })
            }
            finally{
                this.loading=false;
            }
        },
        async forceRenderNew(){
            this.renderNew=false;
            await this.$nextTick();
            this.renderNew=true;
        },
        async postImport(title, file, isNew=false){
            if(isNew && !title){
                this.$swal({
                    title: 'Atenção',
                    text: `Preencha o nome da tabela antes de importar.`,
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C7300C',
                });
                return;
            }

            try{
                this.loading=true;

                var endpoint = null;

                if(isNew) endpoint = 'commissions/import';
                else if(title.trim()=='Guia de Fundos') endpoint = 'funds/import';
                else if(title.trim()=='Guia de fundos previdência') endpoint = 'pension-guide/import';
                else if(title.trim()=='Diversificação') endpoint = 'diversifications/import';
                else if(title.trim()=='XPUS') endpoint = 'diversifications/xp-us/import';
                else if(title.trim()=='Contratação Fee Fixo') endpoint = 'contract-fee-fixo/import';
                else if(this.listCommission.find(e=>e.table_name==title)) endpoint = 'commissions/import';
                else if(title.trim()=='Tabela de comissão PJ2') endpoint = 'commission-only/import';

                const formData = new FormData();
                formData.append('sheet', file);

                await this.$axios.post(endpoint+'?table_name='+title.trim(), formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })

                this.forceRenderNew();

                // if(isNew) this.$router.go();
                // else 
                    this.getStratification();
            }
            catch(error){
                this.$swal({
                    title: 'Erro',
                    text: `Não foi possível realizar a importação.`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C7300C',
                })
            }
            finally{
                this.loading=false;
            }
            
        },
        getNewTable(title){
            this.new_table_name=title;
        },


        getDateTitle(){
            const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
            const dataAtual = new Date(this.reference_date);
            const ano = dataAtual.getFullYear();
            const mes = dataAtual.getMonth();
            const mesString = months[mes].toUpperCase();
            this.month = mes+1;
            this.year = ano;
            return mesString + ' ' + ano;
        },
    }
}
</script>

<style>
    #dashboard {
        height: calc(100vh - 125px);
    }

    .form-control {
        background-color: #262625 !important;
        border: 1px solid #535252 !important;
        color: #E5E2E1 !important;
        border-radius: 10px !important;
    }
</style>
