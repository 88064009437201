<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            title="Regras pendentes"
            :haveBackButton="true"
            @onBack="$router.push({name: 'dashboard'})"
            :haveContentMargin="true"
            >

            <!-- CONTENT -->
            <b-col class="p-4" style="border-radius:10px; border: 1px solid #555; min-height:100%;">

                <b-row class="m-0 pb-3">
                    <span style="font-size:18px; font-weight:500;">Custódia não mapeada</span>
                </b-row>
                <b-table class="custom-table" borderless sticky-header :fields="columns" :items="items" show-empty>
                    <template #empty="">
                        <b-col class="text-center pb-3">
                            Não existem regras pendentes
                        </b-col>
                    </template>
                </b-table>

                <div class="py-3"></div>

                <b-row class="m-0 pb-3">
                    <span style="font-size:18px; font-weight:500;">Receita não mapeada</span>
                </b-row>
                <b-table class="custom-table" borderless sticky-header :fields="columns" :items="items" show-empty>
                    <template #empty="">
                        <b-col class="text-center pb-3">
                            Não existem regras pendentes
                        </b-col>
                    </template>
                </b-table>
            </b-col>

            <template #footer>
                <b-row class="m-0" align-h="end">
                    <CustomButton
                    :disabled="true"
                    > Definir regras
                    </CustomButton>
                </b-row>
            </template>

            </DefaultPageComponent>

        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
export default {
    name: "PendingRulesView",
    components:{
        DefaultPageComponent,
        CustomButton,
    },
    data(){
        return {
            loading: false,
            loadingSubmit: false,
            items: [],
            columns: [
                {
                    'product': 
                    {
                        label: 'Produto',
                        class: 'text-left',
                    }
                },
                {
                    'sub_product': 
                    {
                        label: 'Sub produto',
                        class: 'text-left',
                    }
                },
                {
                    'cnpj': 
                    {
                        label: 'CNPJ',
                        class: 'text-left',
                    }
                },
                {
                    'active': 
                    {
                        label: 'Ativo',
                        class: 'text-left',
                    }
                },
                {
                    'sender': 
                    {
                        label: 'Emissor',
                        class: 'text-left',
                    }
                },
                {
                    'classification': 
                    {
                        label: 'Classificação',
                        class: 'text-left',
                    }
                },
                {
                    'sub_classification': 
                    {
                        label: 'Sub classificação',
                        class: 'text-left',
                    }
                },
                // {
                //     'actions': 
                //     {
                //         label: 'Ações',
                //         class: 'text-right',
                //     }
                // },
            ],
        }
    },
    created(){
        this.getStatus();
        this.getCategories();
        this.getItems();
    },
    // computed:{
    //     isDisabled(){
    //         return this.loading || this.loadingSubmit || !this.file;
    //     },
    // },
    methods: {
        async getItems(){
            try{
                this.loading=true;
                // var endpoint = 'goals-cockpit';
                // if(this.selectedYear) endpoint+='?year='+this.selectedYear;
                // if(this.advisor) endpoint+='&advisor='+this.advisor;
                // if(this.cell) endpoint+='&cell='+this.cell;
    
                // const response = await this.$axios.get(endpoint);
                // this.items = response.data.goals_cockpit;
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        async getStatus(){
            // const response = await this.$axios.get('');
            // this.listStatus = response.data.data;
        },
        async getCategories(){
            // const response = await this.$axios.get('');
            // this.listCategories = response.data.data;
        },
        async saveNew(){
            // if(!this.file) {
            //     this.$swal({
            //         title: 'Atenção',
            //         text: 'A importação do arquivo é obrigatória.',
            //         icon: 'warning',
            //         confirmButtonText: 'OK',
            //         confirmButtonColor: '#28A745',
            //         allowOutsideClick: false,
            //         customClass: {
            //             confirmButton: 'custom-confirm-button-class',
            //         }
            //     });
            //     return false;
            // }
            // try{
            //     this.loading = true;
            //     this.loadingSubmit = true;

            //     const post = await this.saveGoalsFileImport();
                
            //     await Promise.all([post]);
                
            //     this.$swal({
            //         title: 'Sucesso',
            //         text: 'Arquivo importado com sucesso.',
            //         icon: 'success',
            //         confirmButtonText: 'OK',
            //         confirmButtonColor: '#28A745',
            //         allowOutsideClick: false,
            //         focusConfirm: false,
            //         customClass: {
            //             confirmButton: 'custom-confirm-button-class',
            //         }
            //     });
    
            //     this.clear();
            //     this.$refs.modalNewGoal.closeModal();
            // }
            // catch(error){
            //     console.log(error);
            //     this.$swal({
            //         title: 'Atenção',
            //         text: 'Houve um erro com a importação do arquivo!',
            //         icon: 'error',
            //         confirmButtonText: 'OK',
            //         confirmButtonColor: '#28A745',
            //         allowOutsideClick: false,
            //         customClass: {
            //             confirmButton: 'custom-confirm-button-class',
            //         }
            //     });
            // }
            // finally {
            //     this.loading = false;
            //     this.loadingSubmit = false;
            // }
        },
    },
}
</script>

<style scoped>
/* * /deep/ .modal-card{
    max-width:fit-content !important;
} */
</style>