<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            title="Nova campanha"
            :haveBackButton="true"
            @onBack="backToModal"
            :haveContentMargin="true"
            >

            <template #filters>
                <b-row class="mx-0">
                    <b-col>
                        <div class="form-group" style="opacity:0.5;">
                            <label for="">Categoria de investimento:</label>
                            <input disabled 
                            class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" 
                            v-model="newType">
                        </div>
                    </b-col>
                    <b-col>
                        <div class="form-group">
                            <label for="">Filtrar por:</label>
                            <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" 
                            v-model="filterBy">
                                <option value="">Todas</option>
                                <option value="active">Ativo</option>
                                <option value="issuer">Emissor</option>
                                <option value="cnpj" v-if="newType!='Renda Fixa'">CNPJ</option>
                            </select>
                        </div>
                    </b-col>
                    <b-col v-if="newType=='Renda Fixa'">
                        <InputDatePicker
                        :inputId="'inputDueDateStart'"
                        @input="(value)=>{ dueDateStart=value; getMinDate(dueDateStart) }"
                        label="Data de vencimento de:"
                        placeholder="Selecione"
                        :value="dueDateStart"
                        :initialDate="dueDateEnd"
                        :maxDate="maxDate"
                        resetButton="Cancelar"
                        />
                    </b-col>
                    <b-col v-if="newType=='Renda Fixa'">
                        <InputDatePicker
                        :inputId="'inputDueDateEnd'"
                        @input="(value)=>{ dueDateEnd=value; getMaxDate(dueDateEnd) }"
                        label="Data de vencimento até:"
                        placeholder="Selecione"
                        :value="dueDateEnd"
                        :initialDate="dueDateStart"
                        :minDate="minDate"
                        resetButton="Cancelar"
                        />
                    </b-col>
                    <b-col>
                        <div class="form-group">
                            <label for="">Pesquisar:</label>
                            <input type="text" class="form-control form-control-sm" id="inputUserName" placeholder="Digite aqui" 
                            style="box-shadow: none; height: 56px;" 
                            v-model="query">
                        </div>
                    </b-col>
                    <b-col v-if="newType!='Renda Fixa'"></b-col>
                    <b-col v-if="newType!='Renda Fixa'"></b-col>
                    <b-col>
                        <CustomButton
                        @click="getItems"
                        > Pesquisar
                        </CustomButton>
                    </b-col>
                </b-row>
            </template>

            <!-- CONTENT -->
            <b-table class="custom-table" borderless sticky-header :fields="columns" :items="items" show-empty>
                <template #empty="">
                    <b-col class="text-center pb-3">
                        Pesquise o ativo
                    </b-col>
                </template>
                <template #cell(actions)="row">
                    <CustomButton v-if="row"
                    disabled
                    > Adicionar
                    </CustomButton>
                </template>
            </b-table>

            <template #footer>
                <b-row class="m-0" align-h="between">
                    <CustomButton
                    @click="backToModal"
                    :disabled="true"
                    > Voltar
                    </CustomButton>


                    <b-row>
                        <CustomButton
                        variant="success"
                        disabled
                        bgColor="#28A745"
                        borderColor="#28A745"
                        textColor="#28A745"
                        > 
                            <b-icon icon="bag" class="mr-2"></b-icon>
                            Ativos incluídos
                        </CustomButton>

                        <div class="mx-3"></div>

                        <CustomButton
                        :disabled="true"
                        > Próximo
                        </CustomButton>
                    </b-row>
                </b-row>
            </template>

            </DefaultPageComponent>

        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import InputDatePicker from '@/components/InputDatePicker.vue';
export default {
    name: "PendingRulesView",
    components:{
        DefaultPageComponent,
        CustomButton,
        InputDatePicker,
    },
    data(){
        return {
            loading: false,
            loadingSubmit: false,
            items: [],
            minDate: null,
            maxDate: null,
            dueDateStart: null,
            dueDateEnd: null,
            filterBy: '',
            query: '',
            newType: '',
            rendaFixaColumns: [
                {
                    'product': 
                    {
                        label: 'Categoria',
                        class: 'text-left',
                    }
                },
                {
                    'sub_product': 
                    {
                        label: 'Sub categoria',
                        class: 'text-left',
                    }
                },
                {
                    'active': 
                    {
                        label: 'Ativo',
                        class: 'text-left',
                    }
                },
                {
                    'issuer': 
                    {
                        label: 'Emissor',
                        class: 'text-left',
                    }
                },
                {
                    'due_date': 
                    {
                        label: 'Data de vencimento',
                        class: 'text-left',
                    }
                },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],
            columns: [
                {
                    'product': 
                    {
                        label: 'Categoria',
                        class: 'text-left',
                    }
                },
                {
                    'sub_product': 
                    {
                        label: 'Sub categoria',
                        class: 'text-left',
                    }
                },
                {
                    'n_document_fund': 
                    {
                        label: 'CNPJ',
                        class: 'text-left',
                    }
                },
                {
                    'active': 
                    {
                        label: 'Ativo',
                        class: 'text-left',
                    }
                },
                {
                    'issuer': 
                    {
                        label: 'Emissor',
                        class: 'text-left',
                    }
                },
                {
                    'roa_funds': 
                    {
                        label: 'ROA',
                        class: 'text-left',
                    }
                },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],
        }
    },
    mounted(){
        if(!this.$route.params.type) this.$router.push({name:'campaigns'})
        this.newType = this.$route.params.type;
    },
    // computed:{
    //     isDisabled(){
    //         return this.loading || this.loadingSubmit || !this.file;
    //     },
    // },
    methods: {
        backToModal(){
            this.$router.push({ name:'campaigns', params: {showModal: true, type: this.newType} })
        },
        async getMinDate(date){
            if(!date) this.minDate=null;
            else this.minDate = new Date(date).toISOString().split('T')[0];
        },
        async getMaxDate(date){
            if(!date) this.maxDate=null;
            else this.maxDate = new Date(date).toISOString().split('T')[0];
        },
        async getItems(){
            try{
                this.loading=true;

                var classification_id = null;
                if(this.newType=='Renda Fixa') classification_id = 4;
                else if(this.newType=='Fundos') classification_id = 3;
                else if(this.newType=='Previdência') classification_id = 1;

                var endpoint = 'campaigns/eligible-actives?classification_id='+classification_id;

                if(this.dueDateStart || this.dueDateEnd){
                    var start = '';
                    if(this.dueDateStart) start = new Date(this.dueDateStart).toISOString().split('T')[0];
                    var end = '';
                    if(this.dueDateEnd) start = new Date(this.dueDateEnd).toISOString().split('T')[0];
                    endpoint+='&due_date='+(start&&end ? start+','+end : start||end);
                }

                if(this.filterBy=='active') endpoint+='&active='+this.query;
                else if(this.filterBy=='issuer') endpoint+='&issuer='+this.query;
                else if(this.filterBy=='cnpj') endpoint+='&n_document_fund='+this.query;
                else if(!this.filterBy && this.query){
                    endpoint+='&active='+this.query;
                    endpoint+='&issuer='+this.query;
                    endpoint+='&n_document_fund='+this.query;
                }
    
                const response = await this.$axios.get(endpoint);
                this.items = response.data;
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        async getStatus(){
            // const response = await this.$axios.get('');
            // this.listStatus = response.data.data;
        },
        async getCategories(){
            // const response = await this.$axios.get('');
            // this.listCategories = response.data.data;
        },
        async saveNew(){
            // if(!this.file) {
            //     this.$swal({
            //         title: 'Atenção',
            //         text: 'A importação do arquivo é obrigatória.',
            //         icon: 'warning',
            //         confirmButtonText: 'OK',
            //         confirmButtonColor: '#28A745',
            //         allowOutsideClick: false,
            //         customClass: {
            //             confirmButton: 'custom-confirm-button-class',
            //         }
            //     });
            //     return false;
            // }
            // try{
            //     this.loading = true;
            //     this.loadingSubmit = true;

            //     const post = await this.saveGoalsFileImport();
                
            //     await Promise.all([post]);
                
            //     this.$swal({
            //         title: 'Sucesso',
            //         text: 'Arquivo importado com sucesso.',
            //         icon: 'success',
            //         confirmButtonText: 'OK',
            //         confirmButtonColor: '#28A745',
            //         allowOutsideClick: false,
            //         focusConfirm: false,
            //         customClass: {
            //             confirmButton: 'custom-confirm-button-class',
            //         }
            //     });
    
            //     this.clear();
            //     this.$refs.modalNewGoal.closeModal();
            // }
            // catch(error){
            //     console.log(error);
            //     this.$swal({
            //         title: 'Atenção',
            //         text: 'Houve um erro com a importação do arquivo!',
            //         icon: 'error',
            //         confirmButtonText: 'OK',
            //         confirmButtonColor: '#28A745',
            //         allowOutsideClick: false,
            //         customClass: {
            //             confirmButton: 'custom-confirm-button-class',
            //         }
            //     });
            // }
            // finally {
            //     this.loading = false;
            //     this.loadingSubmit = false;
            // }
        },
    },
}
</script>

<style scoped>
/* * /deep/ .modal-card{
    max-width:fit-content !important;
} */
</style>