<template>
    <div class="d-flex align-items-center justify-content-center" style="height: 100%; width: 100%;">
        <div style="height: 100%; width: 100%;">
        <b-form @submit.stop.prevent="onSubmit" style="height: 100%; width: 100%;">
            <DefaultPageComponent
            :loading="loading"
            :title="'Novo assessor'"
            :haveBackButton="true"
            @onBack="$router.push({name: 'advisor'})"
            >

            <!-- CONTENT -->
            <div class="py-3" style="background-color: #1C1B1B; color: #000; border-radius: 0px 0px 8px 8px;" >
                    <div class="pb-3 mx-3" style="color:white;">Dados do Assessor</div>
                    <div class="py-2 mx-0 row d-flex justify-content-left">
                        <div class="col-md-6">
                            <b-form-group id="inputGroupFirstName" label="Nome" label-for="inputGroupFirstName">
                                <b-form-input
                                    id="inputFirstName"
                                    name="inputFirstName"
                                    v-model="advisor.first_name"
                                    style="min-height:56px;"
                                    v-validate="{ required: true }"
                                    :state="validateState('inputFirstName')"
                                    aria-describedby="inputFirstNameFeedback"
                                    data-vv-as="Nome"
                                    :disabled="loading"
                                    required
                                ></b-form-input>

                                <b-form-invalid-feedback id="inputFirstNameFeedback">{{ veeErrors.first('inputFirstName') }}</b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group id="inputGroupLastName" label="Sobrenome" label-for="inputGroupLastName">
                                <b-form-input
                                    id="inputLastName"
                                    name="inputLastName"
                                    v-model="advisor.last_name"
                                    style="min-height:56px;"
                                    v-validate="{ required: true }"
                                    :state="validateState('inputLastName')"
                                    aria-describedby="inputLastNameFeedback"
                                    data-vv-as="Sobrenome"
                                    :disabled="loading"
                                    required
                                ></b-form-input>

                                <b-form-invalid-feedback id="inputLastNameFeedback">{{ veeErrors.first('inputLastName') }}</b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="py-2 mx-0 row d-flex justify-content-left">
                        <div class="col-md-6">
                            <b-form-group id="inputGroupNDocument" label="CPF" label-for="inputGroupNDocument">
                                <b-form-input
                                    id="inputNDocument"
                                    name="inputNDocument"
                                    v-model="advisor.n_document"
                                    style="min-height:56px;"
                                    v-validate="{ required: true, cpf: true }"
                                    :state="validateState('inputNDocument')"
                                    aria-describedby="inputNDocumentFeedback"
                                    data-vv-as="CPF"
                                    v-mask="'###.###.###-##'"
                                    :disabled="loading"
                                    required
                                ></b-form-input>

                                <b-form-invalid-feedback id="inputNDocumentFeedback">{{ veeErrors.first('inputNDocument') }}</b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group id="inputGroupEmail" label="E-mail" label-for="inputGroupEmail">
                                <b-form-input
                                    id="inputEmail"
                                    name="inputEmail"
                                    v-model="advisor.email"
                                    style="min-height:56px;"
                                    v-validate="{ required: true, email: true }"
                                    :state="validateState('inputEmail')"
                                    aria-describedby="inputEmailFeedback"
                                    data-vv-as="E-email"
                                    :disabled="loading"
                                    required
                                ></b-form-input>

                                <b-form-invalid-feedback id="inputEmailFeedback">{{ veeErrors.first('inputEmail') }}</b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="py-2 mx-0 row d-flex justify-content-left">
                        <div class="col-md-6">
                            <b-form-group id="inputGroupCompany" label="Unidade" label-for="inputGroupCompany">
                                <b-form-select
                                    id="inputCompany"
                                    name="inputCompany"
                                    class="form-control"
                                    v-model="advisor.company_id"
                                    style="min-height:56px;"
                                    v-validate="{}"
                                    :state="validateState('inputCompany')"
                                    aria-describedby="inputCompanyFeedback"
                                    data-vv-as="Unidade"
                                    :disabled="loading"
                                >
                                    <b-form-select-option v-for="company in companies" :value="company.id" :key="company.id">{{ company.city }} / {{ company.state.acronym }}</b-form-select-option>
                                </b-form-select>

                                <b-form-invalid-feedback id="inputCompanyFeedback">{{ veeErrors.first('inputCompany') }}</b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group id="inputGroupCell" label="Célula" label-for="inputGroupCell">
                                <b-form-select
                                    id="inputCell"
                                    name="inputCell"
                                    class="form-control"
                                    v-model="advisor.cell_id"
                                    style="min-height:56px;"
                                    v-validate="{}"
                                    :state="validateState('inputCell')"
                                    aria-describedby="inputCellFeedback"
                                    data-vv-as="Célula"
                                    :disabled="loading"
                                >
                                    <b-form-select-option v-for="cell in cells" :value="cell.id" :key="cell.id">{{ cell.cell }}</b-form-select-option>
                                </b-form-select>

                                <b-form-invalid-feedback id="inputCellFeedback">{{ veeErrors.first('inputCell') }}</b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="py-2 mx-0 row d-flex justify-content-left">
                        <div class="col-md-6">
                            <b-form-group id="inputGroupType" label="Tipo" label-for="inputGroupType">
                                <b-form-select
                                    id="inputType"
                                    name="inputType"
                                    class="form-control"
                                    v-model="advisor.title"
                                    style="min-height:56px;"
                                    v-validate="{}"
                                    :state="validateState('inputType')"
                                    aria-describedby="inputTypeFeedback"
                                    data-vv-as="Célula"
                                    :disabled="loading"
                                >
                                    <b-form-select-option value="null" key="null">Selecione</b-form-select-option>
                                    <b-form-select-option value="0" key="0">Assessor</b-form-select-option>
                                    <b-form-select-option value="1" key="1">Parceiro</b-form-select-option>
                                </b-form-select>

                                <b-form-invalid-feedback id="inputTypeFeedback">{{ veeErrors.first('inputType') }}</b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="py-3 row d-flex align-items-center justify-content-between mb-3 mx-3" style="border-bottom:1px solid #535252">
                        <div class="form-group" style="max-width:fit-content;">
                            <span style="color:white;">Parceiros comerciais</span>
                        </div>
                        <GlassButton
                        :loading="loading"
                        @click="showModalCommercialPartnerForm()"
                        > 
                            <span style="font-size:30px;"><b-icon icon="plus"></b-icon></span>
                            Adicionar
                        </GlassButton>
                    </div>
                    <div class="mx-0 row d-flex justify-content-center align-items-center">
                        <div class="col mt-2">
                            <b-table :key="keyTable" striped borderless small sticky-header :fields="columns" :items="commercialPartnersInMemory" style="max-height: 60vh !important;" show-empty empty-text="Sem registros para mostrar no momento..." head-variant="dark">
                                <template #cell(actions)="row">
                                    <div class="d-flex justify-content-end align-items-center">
                                        <b-button 
                                            size="sm"
                                            @click="showModalCommercialPartnerForm(row.item)" class="px-2"
                                            style="color: #EB694A; border:1px solid #474646; border-radius:4px; box-shadow: none; background-color: transparent; font-size:16px;"
                                        >
                                            <b-icon icon="pencil-fill"></b-icon>
                                        </b-button>
                                        <div style="width:10px;"></div>
                                        <b-button 
                                            size="sm"
                                            @click="removeCommercialPartner(row.index)" class="px-2"
                                            style="color: #EB694A; border:1px solid #474646; border-radius:4px; box-shadow: none; background-color: transparent; font-size:16px;"
                                        >
                                            <b-icon icon="trash-fill"></b-icon>
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                    </div>
            </div>

            <template #footer>
                <div class="row d-flex align-items-center justify-content-end mx-3 mt-3">
                    <CancelButton
                    @click="$router.push({name: 'advisor'})"
                    >
                        Cancelar
                    </CancelButton>
                    <CustomButton
                    :type="'submit'"
                    :disabled="!isFormValid"
                    :loading="loading || loadingSubmit"
                    > 
                        {{ type=='post' ? 'Adicionar' : 'Salvar' }}
                    </CustomButton>
                </div>
            </template>

            </DefaultPageComponent>
            </b-form>
        </div>
        <card-modal :title="type=='post' ? 'Adicionar parceiro comercial' : 'Editar parceiro comercial'" ref="modalCommercialPartnerForm">
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col mt-2">
                    <b-form @submit.stop.prevent="onSubmitCommercialPartnerForm">
                        <div class="row d-flex justify-content-left mx-0">
                            <!-- <div class="col-md-2"> -->
                            <div class="col-md-4">
                                <b-form-group id="inputGroupCode" label="Código" label-for="inputGroupCode">
                                    <b-form-input
                                        id="inputCommercialPartnerCode"
                                        name="inputCommercialPartnerCode"
                                        v-model="commercialPartner.code"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        :state="validateState('inputCommercialPartnerCode')"
                                        aria-describedby="inputCommercialPartnerCodeFeedback"
                                        data-vv-as="Código"
                                        :disabled="loading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback id="inputCommercialPartnerCodeFeedback">{{ veeErrors.first('inputCommercialPartnerCode') }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <!-- <div class="col-md-3">
                                <b-form-group id="inputGroupOpenedDate" label="Data de abertura" label-for="inputGroupOpenedDate">
                                    <b-form-input
                                        id="inputOpenedDate"
                                        name="inputOpenedDate"
                                        type="date"
                                        v-model="commercialPartner.openedDate"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        class="form-control"
                                        :state="validateState('inputOpenedDate')"
                                        aria-describedby="inputOpenedDateFeedback"
                                        data-vv-as="Unidade"
                                        :disabled="loading"
                                    >
                                    </b-form-input>

                                    <b-form-invalid-feedback id="inputOpenedDateFeedback">{{ veeErrors.first('inputOpenedDate') }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <div class="col-md-3">
                                <b-form-group id="inputGroupActiveDate" label="Data de ativação" label-for="inputGroupActiveDate">
                                    <b-form-input
                                        id="inputActiveDate"
                                        name="inputActiveDate"
                                        type="date"
                                        v-model="commercialPartner.activeDate"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        class="form-control"
                                        :state="validateState('inputActiveDate')"
                                        aria-describedby="inputActiveDateFeedback"
                                        data-vv-as="Unidade"
                                        :disabled="loading"
                                    >
                                    </b-form-input>

                                    <b-form-invalid-feedback id="inputActiveDateFeedback">{{ veeErrors.first('inputActiveDate') }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div> -->
                            <!-- <div class="col-md-4"> -->
                            <div class="col-md-8">
                                <b-form-group id="inputGroupCommercialPartnerId" label="Nome" label-for="inputGroupCommercialPartnerId">
                                    <b-form-select
                                        id="inputCommercialPartnerId"
                                        name="inputCommercialPartnerId"
                                        v-model="commercialPartner.id"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        class="form-control"
                                        :state="validateState('inputCommercialPartnerId')"
                                        aria-describedby="inputCommercialPartnerIdFeedback"
                                        data-vv-as="Unidade"
                                        :disabled="loading"
                                    >
                                        <b-form-select-option v-for="commercialPartner in commercialPartners" :value="commercialPartner.id" :key="commercialPartner.id">{{ commercialPartner.trade_name }}</b-form-select-option>
                                    </b-form-select>

                                    <b-form-invalid-feedback id="inputCommercialPartnerIdFeedback">{{ veeErrors.first('inputCommercialPartnerId') }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row d-flex align-items-center justify-content-end mx-3 mt-3">
                            <CancelButton
                            @click="$refs.modalCommercialPartnerForm.closeModal()"
                            >
                                Cancelar
                            </CancelButton>
                            <CustomButton
                            @click="onSubmitCommercialPartnerForm"
                            :disabled="!commercialPartner.id || !commercialPartner.code"
                            :loading="loadingSubmit"
                            > 
                                {{ type=='post' ? 'Adicionar' : 'Salvar' }}
                            </CustomButton>
                        </div>
                    </b-form>
                </div>
            </div>
        </card-modal>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CardModal from './CardModal.vue';
import GlassButton from '@/components/GlassButton.vue';
import CustomButton from '@/components/CustomButton.vue';
import CancelButton from '@/components/CancelButton.vue';

export default {
    name: 'AdvisorFormView',
    components: {
        DefaultPageComponent,
        'card-modal': CardModal,
        GlassButton,
        CustomButton,
        CancelButton,
    },
    async mounted() {
        await this.getCompanies();
        await this.getCells();
        await this.getCommercialPartners();
    },
    created() {
        this.$validator.localize('en', {
            custom: {
                inputFirstName: {
                    required: 'Campo obrigatório.',
                },
                inputLastName: {
                    required: 'Campo obrigatório.',
                },
                inputEmail: {
                    required: 'Campo obrigatório.',
                    email: 'E-mail inválido.',
                },
                inputNDocument: {
                    required: 'Campo obrigatório.',
                    cpf: 'CPF inválido.',
                },
                inputCommercialPartnerId: {
                    required: 'Campo obrigatório.',
                },
                inputCommercialPartnerCode: {
                    required: 'Campo obrigatório.',
                },
            },
        });
    },
    data() {
        return {
            loading: true,
            formIsValid: false,
            companies: [],
            cells: [],
            commercialPartners: [],
            advisor: {
                first_name: '',
                last_name: '',
                email: '',
                n_document: '',
                company_id: '',
                cell_id: '',
                title: '',
            },
            loadingSubmit: false,
            columns: [
                {
                    'code': 
                    {
                        label: 'Código',
                        class: 'text-left'
                    }
                },
                {
                    'name': 
                    {
                        label: 'Parceiro comercial',
                        class: 'text-left'
                    }
                },
                // {
                //     'openedDate': 
                //     {
                //         label: 'Data de abertura',
                //         class: 'text-left',
                //         formatter: (value)=>{
                //             if(!value) return '---';
                //             return value.substring(8,10)+'/'+value.substring(5,7)+'/'+value.substring(0,4)
                //         }
                //     }
                // },
                // {
                //     'activeDate': 
                //     {
                //         label: 'Data de ativação',
                //         class: 'text-left',
                //         formatter: (value)=>{
                //             if(!value) return '---';
                //             return value.substring(8,10)+'/'+value.substring(5,7)+'/'+value.substring(0,4)
                //         }
                //     }
                // },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],
            commercialPartner: {
                code: '',
                id: '',
                name: '',
            },
            commercialPartnersInMemory: [],
            type: 'post',
            keyTable: true,
        }
    },
    computed:{
        isFormValid(){
            return (
                this.advisor.first_name
                && this.advisor.last_name
                && this.advisor.n_document
                && this.advisor.email
                && this.commercialPartnersInMemory.length>0
            )
        },
    },
    methods: {
        async getCompanies() {
            this.loading=true;
            this.$axios.get('companies').then((response) => {
                this.companies = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar às unidades.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        async getCells() {
            this.loading=true;
            this.$axios.get('cells').then((response) => {
                this.cells = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar às células.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        async getCommercialPartners() {
            this.loading=true;
            await this.$axios.get('commercial-partner').then((response) => {
                this.commercialPartners = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os parceiro comercial.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        async validateForm() {
            let response = await this.$validator.validateAll(
                [
                    'inputFirstName', 
                    'inputLastName', 
                    'inputEmail', 
                    'inputNDocument'
                ]
            );
            
            if (response)
                this.formIsValid = true;
            else
                this.formIsValid = false;
        },
        showModalCommercialPartnerForm(item) {
            if (this.commercialPartners.length == 0) {
                this.$swal({
                    title: 'Informação',
                    text: 'Sem parceiros disponíveis para selecionar, todos já estão selecionados.',
                    icon: 'info',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
            if(item){
                this.type = 'put';
                this.commercialPartner = {...item};
                this.commercialPartner.idx = this.commercialPartnersInMemory.findIndex(e=>e==item);
            }
            else{
                this.type = 'post';
                this.commercialPartner.code = "";
                this.commercialPartner.id = "";
                this.commercialPartner.name = "";
                // this.commercialPartner.openedDate = "";
                // this.commercialPartner.activeDate = "";
            }
            this.$refs.modalCommercialPartnerForm.openModal();
        },
        onSubmit() {
            if(
                !this.advisor.first_name
                || !this.advisor.last_name
                || !this.advisor.n_document
                || !this.advisor.email
            ){
                this.$swal({
                    title: 'Atenção',
                    text: 'Para concluír o cadastro, é necessário preencher todos os campos obrigatórios.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
            else if(this.commercialPartnersInMemory.length==0){
                this.$swal({
                    title: 'Atenção',
                    text: 'Para concluír o cadastro, é necessário adicionar um parceiro comercial.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }

            this.$validator.validateAll().then(result => {
                if (!result) {
                    return;
                }

                this.loadingSubmit = true;

                let objAdvisor = this.advisor;
                objAdvisor.n_document = objAdvisor.n_document.replace(/\D/g, '');

                if(this.commercialPartnersInMemory.length > 0)
                    objAdvisor.commercial_partners = this.commercialPartnersInMemory;

                this.$axios.post('advisors', objAdvisor).then(() => {
                    this.$swal({
                        title: 'Sucesso',
                        text: 'Assessor cadastrado com sucesso.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#28A745',
                        allowOutsideClick: false,
                        focusConfirm: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                    this.loadingSubmit = false;
                    this.advisor = {};
                    this.$router.push({ name: 'advisor' });
                }).catch(() => {
                    this.$swal({
                        title: 'Erro',
                        text: 'Não foi possível cadastrar o assessor.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#C44C30',
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                    this.loadingSubmit = false;
                });
            });
        },
        onSubmitCommercialPartnerForm() {
            if(this.type=='post' && this.commercialPartnersInMemory.find(e=>e.id==this.commercialPartner.id && e.code==this.commercialPartner.code)){
                let idx = this.commercialPartners.findIndex(obj => obj.id === this.commercialPartner.id);
                this.$swal({
                    title: 'Atenção',
                    html: '<div> O code: <b>'+this.commercialPartner.code+'</b> com o Parceiro comercial: <b>'+this.commercialPartners[idx].trade_name+'</b> já existe no cadastro.</div>',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }

            this.$validator.validateAll(['inputCommercialPartnerCode', 'inputCommercialPartnerId']).then(result => {
                if (!result) {
                    return;
                }
                
                let idx = this.commercialPartners.findIndex(obj => obj.id === this.commercialPartner.id);
                this.commercialPartner.name = this.commercialPartners[idx].trade_name;

                if(this.type=='post'){
                    this.commercialPartnersInMemory.push({
                        code: this.commercialPartner.code,
                        id: this.commercialPartner.id,
                        name: this.commercialPartner.name,
                        // // openedDate: this.commercialPartner.openedDate,
                        // activeDate: this.commercialPartner.activeDate,
                    });
                }
                else if(this.type=='put'){
                    this.commercialPartnersInMemory[this.commercialPartner.idx] = {
                        code: this.commercialPartner.code,
                        id: this.commercialPartner.id,
                        name: this.commercialPartner.name,
                        // // openedDate: this.commercialPartner.openedDate,
                        // // activeDate: this.commercialPartner.activeDate,
                    }
                }

                // this.commercialPartners.splice(idx, 1);
                
                this.renderTable();

                this.$refs.modalCommercialPartnerForm.closeModal();
            });
        },
        async renderTable(){
            this.keyTable=false;
            await this.$nextTick();
            this.keyTable=true;
        },
        async removeCommercialPartner(idx) {
            this.$swal({
                title: 'Excluir parceiro comercial ?',
                text: `Deseja realmente excluir do cadastro do assessor o código ${this.commercialPartnersInMemory[idx].code} do parceiro comercial ${this.commercialPartnersInMemory[idx].name} ?`,
                // text: `Deseja realmente excluir o parceiro comercial ${this.commercialPartnersInMemory[idx].name}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                cancelButtonText: 'Excluir',
                cancelButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    cancelButton: 'custom-confirm-button-class',
                }
            }).then(async (result) => {
                if (!result.isConfirmed) {
                    this.commercialPartnersInMemory.splice(idx, 1);
                    this.commercialPartners = [];
                    await this.getCommercialPartners();
                    for (let i = 0; i < this.commercialPartnersInMemory.length; i++) {
                        let idx = this.commercialPartners.findIndex(obj => obj.id === this.commercialPartnersInMemory[i].id);

                        if (idx > -1) {
                            this.commercialPartners.splice(idx, 1);
                        }
                    }
                    this.renderTable();
                }
            });
        }
    },
    watch: {
        'advisor.first_name': 'validateForm',
        'advisor.last_name': 'validateForm',
        'advisor.email': 'validateForm',
        'advisor.n_document': 'validateForm',
        'advisor.company_id': 'validateForm',
        'advisor.cell_id': 'validateForm',
        'advisor.title': 'validateForm',
    }
}
</script>
